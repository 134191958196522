<template>
  <div class="login_panel">
    <div  v-if="$store.state.isMaintaining == false" style="width: 100%">
      <div class="logo">
        <img src="../../assets/images/logo/ptn/logo01.png"/>
      </div>
      <div class="field">
        <i class="icon fa fa-user"></i>
        <input type="text" placeholder="아이디" v-model="user.username" @keyup.enter="doLogin"/>
      </div>
      <div class="field">
        <i class="icon fa fa-lock"></i>
        <input type="password" placeholder="비밀번호" v-model="user.passwd" @keyup.enter="doLogin"/>
      </div>
      <div class="field">
        <button type="button" class="loginbutton" @click="doLogin"  @keyup.enter="doLogin">로그인</button>
      </div>
      <div class="field">
        <button type="button" class="regbutton" @click="showRegisterPanel" >회원가입</button>
      </div>
      <div class="error-message" v-show="errmessage != null">
        <i class="fa fa-warning"></i> {{ errmessage }}
      </div>
      <div class="login-panel-close" @click="closePanel">
        <img src="../../assets/images/icon/common/close1.svg"/>
      </div>
    </div>
    <div v-html="$store.state.maintainingMessage" v-if="$store.state.isMaintaining == true">

    </div>
  </div>


</template>

<script>
    import {getSiteStatus, getUserInfo, login} from "../../network/userRequest";
    import {loginRegisterMixin} from "../../common/mixin";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    export default {
        name: "MemberLoginSection",
        components: {
        },
        data() {
            return {
                user: {
                    username: '',
                    passwd: '',
                    hvcode: null,
                },
                isShowMessage: false,
                content: '',
                errmessage: null,
                imageSrc: null,
            }
        },
        methods: {
            doLogin() {

                this.$store.commit(RECEIVE_SHOW_LOADING);
                login(this.user).then(res => {
                    if (res.data.success) {
                        this.errmessage = null
                        getUserInfo().then(res => {
                            this.$store.commit(RECEIVE_HIDE_LOADING);
                            this.$store.state.isLogin = true;
                            this.$store.state.userInfo = res.data.data
                            //this.$store.commit(RECEIVE_USER_INFO, {userInfo});
                            //window.sessionStorage.setItem("isLogin", 'true');
                            this.$router.push('/front/main').catch(error => {
                                console.info(error.message)
                            });
                        }, err => {
                            console.log(err)
                        })
                    } else {
                        this.$store.commit(RECEIVE_HIDE_LOADING);
                        // this.$swal({
                        //     title: res.data.msg,
                        //     type: 'error',
                        //     showCancelButton: false,
                        //     showConfirmButton: true
                        // })
                        this.errmessage = res.data.msg;
                    }
                })
            },
            getHVCode() {
                switch (process.env.NODE_ENV) {
                    case 'development':
                        let prot = window.location.protocol
                        let domain = window.location.host.replaceAll("8080",'7070')
                        this.imageSrc =  prot + '//' + domain + '/api/user/hvcode';
                        break
                    case 'production':
                        let prot2 = window.location.protocol
                        let domain2 = window.location.host
                        this.imageSrc =  prot2 + '//' + domain2 + '/api/user/hvcode';
                        break
                }

            },
            refreshHVcode(){
                window.location.reload()
            },
            showNotMemberCustomerPanel() {

                if (!this.$store.state.isLogin) {
                    this.$store.state.isShowNotMemberCustomerePanel = true
                }
            },
            showRegisterPanel() {
                if (!this.$store.state.isLogin) {
                    this.$store.state.isShowRegisterPanel = true
                    this.$store.state.isShowLoginPanel = false
                }
            },
            closePanel() {
              this.$store.state.isShowLoginPanel = false;
              this.$store.state.isShowRegisterPanel = false
            },
        },
        created() {
            this.getHVCode()
            getSiteStatus().then(res => {
                if (!res.data.success) {
                    this.$store.state.isMaintaining = true;
                    this.$store.state.maintainingMessage = res.data.msg
                    this.$store.state.isLogin = false;
                    this.$store.state.isShowLoginPanel = true;
                } else {
                    this.$store.state.isMaintaining = false;
                    this.$store.state.maintainingMessage = ''
                }
            })
        },
        computed: {

        }
    }
</script>

<style scoped>
    .login_panel {
      width: 40rem;
      background-color: rgb(23, 22, 22);
      color: #fff9ff;
      position: relative;
      border: .2rem solid;
      border-image: linear-gradient(#f8d630, #f1f1f1);
      border-image-slice: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5rem;
    }
    .login_panel .logo{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    .login_panel .logo img{
      width: 10rem;
    }
    .login_panel .field {
      width: 100%;
      position: relative;
      margin: .5rem 0;
    }
    .login_panel .field i{
      position: absolute;
      color: #808080;
      top: .9rem;
      left: 1.9rem;
      font-size: 2.3rem;
    }
    .login_panel .field input {
      width: 100%;
      background-color: #212123;
      border: 0;
      border-radius: 5rem;
      color: #fff9ff;
      font-size: 1.2rem;
      height: 4.4rem;
      outline: 0;
      padding: 0;
      text-align: center;
    }

    .login_panel .field button{
      width: 100%;
      background-color: #212123;
      border: 0;
      border-radius: 5rem;
      color: #fff9ff;
      font-size: 1.2rem;
      height: 4.4rem;
      outline: 0;
      padding: 0;
      text-align: center;
      cursor: pointer;
    }
    .login_panel .field .loginbutton{
      background: linear-gradient(to right, #ff0073, #f8ff42);
    }
    .login_panel .field .regbutton{
      background: linear-gradient(to right, #f8ff42, #ff0073);
    }
    .login_panel .field .loginbutton:hover{
      background: linear-gradient(to top, #ff0073, #f8ff42);
    }
    .login_panel .field .regbutton:hover{
      background: linear-gradient(to top, #f8ff42, #ff0073);
    }

    .login_panel .login-panel-close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 3rem;
      height: 3rem;
      padding: .2rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 1px;
      font-weight: normal;
    }
    .login-panel-close img {
       width: 3rem;
    }


    @media screen and (max-width: 2560px) {

    }

    @media screen and (max-width: 1920px) {

    }

    @media screen and (max-width: 1024px) {
      .login_panel {
        max-width: 37rem;
      }
    }

    .error-message {
      width: 100%;
      text-align: center;
      font-size: 1.4rem;
      box-sizing: border-box;
      color: #f61515;
      animation: sansan 1200ms infinite;
      -webkit-animation: sansan 1200ms infinite;
      margin-top: 1rem;
    }
    .error-message {
      position: relative!important;
    }


</style>
