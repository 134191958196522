<template>
    <div class="login_content  fadeIn fourth">
        <warning-sample></warning-sample>
      <member-login-section></member-login-section>

`
        <div class="p1 popup_section"
             v-if="popup1!=null && popup1.isShow">

            <div class="pop_box">
                <div class="event_img">
                    <a :href="popup1.httplink" target="_blank">
                    <img :src="popup1.imageUrl" alt="" style="width: 100%;">
                    </a>
                </div>
            </div>
            <div class="pfoot" style="">
                <div @click="close24(popup1)" style="text-align: left;cursor: pointer">
                    <i class="fa fa-square"></i> 오늘하루 그만보기
                </div>
                <div style="text-align: right;cursor: pointer">
                    <button class="btn-close" @click="close(popup1)">닫기</button>
                </div>
            </div>

        </div>
        <div class="p2 popup_section"
             v-if="popup2!=null && popup2.isShow">

            <div class="pop_box">
                <div class="event_img">
                    <a :href="popup2.httplink" target="_blank">
                        <img :src="popup2.imageUrl" alt="" style="width: 100%;">
                    </a>
                </div>
            </div>
            <div class="pfoot" style="">
                <div @click="close24(popup2)" style="text-align: left;cursor: pointer">
                    <i class="fa fa-square"></i> 오늘하루 그만보기
                </div>
                <div style="text-align: right;cursor: pointer">
                    <button class="btn-close" @click="close(popup2)">닫기</button>
                </div>
            </div>

        </div>


    </div>


</template>

<script>
import {getPopupList, getSiteStatus} from "../../network/userRequest";
import {loginRegisterMixin} from "../../common/mixin";
import sportsConst from "../../common/sportsConst";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
import WarningSample from "../../components/WarningSample";
import MemberLoginSection from "@/views/afront/MemberLoginSection.vue";

export default {
        name: "MemberLogin",
        components: {
          MemberLoginSection,
            WarningSample,
        },
        mixins: [loginRegisterMixin],
        data() {
            return {
                user: {
                    username: '',
                    passwd: '',
                    hvcode: null,
                },
                isShowMessage: false,
                content: '',
                errmessage: null,
                popup1: null,
                popup2: null,
                imageSrc: null,
            }
        },
        methods: {
            close24(item) {
                this.$set(item, 'isShow', false)
                this.$cookies.set('pop' + item.id, '1', 60 * 60 * 12, '/')
            },
            close(item) {
                //this.$store.state.popups.push(item.id)
                this.$set(item, 'isShow', false)
            },
            getPopList() {
                getPopupList(sportsConst.POPUP_PLATFORM_LOGIN).then(res => {
                    if (res.data.success) {
                        this.plist = res.data.data
                        this.plist.forEach(popup => {
                            let cookiePopupStatus = this.$cookies.get('pop' + popup.id)
                            this.$set(popup, 'isShow', cookiePopupStatus == null)

                        })
                        if (this.plist.length == 1) {
                            this.popup1 = this.plist[0];
                        }
                        if (this.plist.length == 2) {
                            this.popup1 = this.plist[0];
                            this.popup2 = this.plist[1];
                        }
                    }
                })
            },


        },
        created() {
            this.getPopList()
        },
        computed: {
            allowUsername() {
                return /^[a-z][0-9a-z]{3,20}$/.test(this.username)
            },
            allowPasswd() {
                return /^.{3,30}$/.test(this.passwd)
            }
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/front/afrontindex.css");
    .login_content {
        width: 100%;
        height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #0d0b0c url("../../assets/images/login/ptn/login-bg.jpg") no-repeat center center;
      background-size: cover;
    }


    @media screen and (max-width: 1024px) {
        .login_content {
            --background: #0e0f11 url("../../assets/images/login/ptn/login-bg.jpg") no-repeat top;
            --background-size: 100%;
        }
    }


    .p1 {
        position: absolute;
        top: 30%;
        left: 10%;
        z-index: 100002;
    }

    .p2 {
        position: absolute;
        top: 30%;
        right: 10%;
        z-index: 100001;
    }

    .popup_section {
        background-color: #1e1e1e;
        color: #fff9ff;
        margin: 5px;
        width: 375px;
        --border-image: linear-gradient(to right, #ffd680, #ffd680) 1;
        --box-shadow: 0 0 15px #fee394;
    }

    .popup_section h4 {
        line-height: 20px;
        height: 20px;
        width: 100%;
        font-size: 12px;
        padding: 20px;
        color: #cca4fd;
    }

    .popup_section .btn-close {
        padding: 8px 8px;
        background-color: #f1ae17;
        color: #0c0e0e;
    }

    .pop_box {
        box-sizing: border-box;
    }

    .pop_box h2 span:hover {
        color: #f19f09;
    }

    .popup_section .pfoot {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        line-height: 40px
    }

    .pfoot div {
        width: 45%;
        font-size: 15px;
        font-weight: bold;
        padding: 0 5px;
    }

    .samplerver{
        width: 100% !important;
    }
    @media screen and (max-width: 2560px) {
        .popup {
            -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
            transform: translateX(-50%) translateY(-50%) scale(1);

        }
    }

    @media screen and (max-width: 1920px) {
        .popup {
            -webkit-transform: translateX(-50%) translateY(-37%) scale(1);
            transform: translateX(-50%) translateY(-37%) scale(1);

        }
    }

    @media screen and (max-width: 1024px) {

        .p1 {
            top: 10% !important;
            left: 0% !important;
        }

        .p2 {
            top: 10% !important;
            left: 0% !important;
        }

        .popup_section {
            width: 100% !important;
        }
    }
</style>
